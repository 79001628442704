import React, { useState } from 'react'
import { HeroBg, HeroContainer, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroSectionElements'
import Video from '../../assets/video.mp4'
import { Button } from '../buttonElement';
import ButtonMailto from './Mailto';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'

const HeroSection = () => {
  const [hover, setHover] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onHover = () => {
    setHover(!hover)
  }

  const onClick = () => {
    onOpen()
  }

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4"></VideoBg>
      </HeroBg>
      <HeroContent>
        <HeroH1>Voyance</HeroH1>
        <HeroP>Tailored workflow analytics for more productive and sustainable enterprises</HeroP>
        <HeroBtnWrapper>
          {/* <Button onMouseEnter={onHover} onMouseLeave={onHover}
          primary='true' dark='true' to="product" smooth={true}>Contact Us 
           {hover ? " contact@voyance.systems.com" : <ArrowRight/>} </Button> */}
          <Button
            //</HeroBtnWrapper>style={{background:"white"}}
            onMouseEnter={onHover} onMouseLeave={onHover} onClick={onClick} primary='true' dark='false' to="product" smooth={true}>
            Learn more
          </Button>


          {/* <Button onClick={() => window.location = 'mailto:contact@voyance.systems.com'} onMouseEnter={onHover} onMouseLeave={onHover}
          primary='true' dark='true' to="product" smooth={true}>Contact Me {hover ? <ArrowForward/> : <ArrowRight/>} </Button> */}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent >
              <ModalHeader textColor={"#01bf71"}>Voyance Systems</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div style={{ display: 'flex', justifyContent: 'center', height:"calc(100vh - 200px)", alignItems: 'center' }}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/fInfSQ9WIRI" title="Voyance Systems" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>

        </HeroBtnWrapper>
      </HeroContent>
      {/*<Box position='fixed' bottom='-5px' right='10px' zIndex={2}>
          <HeroBtnWrapper>
          <Button2 onMouseEnter={onHover} onMouseLeave={onHover}
                primary='true' dark='false' to="blog" smooth={true}>
                  News{hover ? <ArrowForward/> : <ArrowRight/>}</Button2>
          </HeroBtnWrapper>
  </Box>*/}
    </HeroContainer>
  )
}

export default HeroSection