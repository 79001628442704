import React, { useState } from 'react'
import {FormContainer, FormWrapper, FormRow, Column1, Column2, 
  TextWrapper, TopLine, Input, ImgWrapper, Img, Button, InputMessage, BtnWrapper, Paragraph} from './FormElements'
import img from '../../assets/contact.webp';
import ButtonMailto from '../heroSection/Mailto';

const Form = () => {
  const [hover, setHover]= useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  const initialValues = {name: "", email: "", subject: "", message: ""};
  const [ formValues, setFormValues] = useState(initialValues);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormValues({ ...formValues, [name]: value});
  }

  

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if( formValues === initialValues){
      alert('All the fields must be filled')
    }else{
      console.log(formValues);
    }
  }

  return (
    <>
    <FormContainer lightBg={true} id="contact">
      <FormWrapper onSubmit={handleSubmit}>
        <FormRow imgStart={false}>
          <Column1>
          <TextWrapper>
            <TopLine>Contact Us</TopLine>
            <Paragraph darkText={true}>
              Voyance<br></br>
            Inria Startup Studio<br></br>
            655 Av. de l'Europe, 38330 Montbonnot-Saint-Martin, France <br></br><br></br>
            contact@voyance.systems</Paragraph>
            
            {/* <Input type={'text'} placeholder='Name' name='name' onChange={handleChange} required/><br/>
            <Input placeholder='Email' type={'email'} name='email' onChange={handleChange} required/><br/>
            <Input placeholder='Subject' name='subject' onChange={handleChange} required/><br/>
            <InputMessage placeholder='Message' name='message' onChange={handleChange} required/><br/>
            <BtnWrapper>
          <Button type={'submit'} primary='true' dark='true' to="product" smooth={true} onSubmit=
          {handleSubmit}>Send</Button> 
        </BtnWrapper>*/}
        <Button
            //</HeroBtnWrapper>style={{background:"white"}}
            onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' to="product" smooth={true}

            >
           <ButtonMailto  label="Mail Us" mailto="mailto:contact@voyance.systems?subject=[Website]: "   /> 
           </Button>
        

          </TextWrapper> 
          
          </Column1>
          <Column2>
          <ImgWrapper><Img src={img}/></ImgWrapper>
          </Column2>
        </FormRow>
      </FormWrapper>
    </FormContainer>
    </>
  )
}

export default Form