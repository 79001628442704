import styled from 'styled-components'
import 'animate.css';

export const FormContainer = styled.div`{
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};

    @media screen and (max-width: 768px){
        padding: 100px 0;
    }
}`

export const FormWrapper = styled.form`{
    display: grid;
    z-index: 1;
    height: 680px;
    width: 90%;
    margin: 0 auto;
    max-width: 1100px;
    padding: 0 24px;
    justify-content: center;
}`

export const FormRow = styled.div`{
    display: grid;
    grid-auto-columns: minmax(auto, lfr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'`: `'col1 col2'`)};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'`: `'col1 col1' 'col2 col2'`)};      
    }
}`

export const Column1 = styled.div`{
    margin-top: 50px;
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
}`

export const Column2 = styled.div`{
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
}`

export const TextWrapper = styled.div`{
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
}`

export const TopLine = styled.div`{
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}`

export const Heading = styled.h1`{
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px){
        font-size: 32px;      
    }
}`

export const Subtitle = styled.p`{
    max-width: 440px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
}`

export const Paragraph = styled.p`{
    max-width: 440px;
    margin-bottom: 10px;
    font-size: 1.3rem;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    text-justify: inter-word; 
    line-height: 1.5;
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
    
    @media screen and (max-width: 480px){
        font-size: 1.2rem;     
    }
}`

export const ImgWrapper = styled.div`{
    max-width: 555px;
    height: 100%;
}`

export const Img = styled.img`{
    width: 80%;
    max-width: 20rem;
    margin: auto;
    padding-right: 0;

    &:hover{
        animation: jello;
        animation-duration: 1s;
    }
}`

export const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 3px;
  margin-bottom: 15px;
`;

export const InputMessage = styled.textarea`
  padding: 0.5em;
  margin: 0.5em;
  height: 120px;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 3px;
  margin-bottom: 15px;
`;

export const Button = styled.button`{
    border-radius: 50px;
    background: ${({ primary}) => (primary ? '#01bf71': '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px': '12px 30px')};
    font-size: ${({ fontBig}) => (fontBig ? '20px': '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 20px 20px -20px rgba(0,0,0,1);

    &:hover{
        transition: all 0.1s ease-in-out;
        background: ${({ primary}) => (primary ? '#000': '#01bf71')};
        color: ${({ primary}) => (primary ? '#fff': '#000')};
        animation: headShake;
        animation-duration: 1s;
    }
}`

export const BtnWrapper = styled.div`{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}`