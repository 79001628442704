import React from "react";
import { ImgWrapper, MemberName, ImgMember, Role } from "./TeamElements";
import { Box, Flex } from "@chakra-ui/react";
import { AiFillLinkedin } from "react-icons/ai";

function Card({ src, name, role, linkedin }) {
  return (
    <Box w="full">
      <ImgWrapper>
        <ImgMember src={src} />
        <MemberName darkText={true}>{name}</MemberName>
        <Flex align="center" justify={'center'} h={"100%"} w={"100%"}>
          <Role darkText={true}>{role}</Role>
          <a href={linkedin} target="_blank" rel="noreferrer">
            <AiFillLinkedin size="1.8rem" color="#000" />
          </a>
        </Flex>
      </ImgWrapper>
    </Box>
  );
}

export default Card;
