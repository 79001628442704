import styled from 'styled-components'
import {Link} from 'react-scroll'

export const Button = styled(Link)`{
    border-radius: 50px;
    color: #fff;
    background: ${({ primary}) => (primary ? '#01bf71': '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px': '12px 30px')};
    font-size: ${({ fontBig}) => (fontBig ? '20px': '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;

    &:hover{
        transition: all 0.1s ease-in-out;
        background: ${({ primary}) => (primary ? '#010606': '#01bf71')};
    }
}`

//Green: #01bf71 
//Blue: #21a2a2