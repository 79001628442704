import styled from "styled-components";
import "animate.css";

export const TeamContainer = styled.div`
   {
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};

    @media screen and (max-width: 768px) {
      padding: 100px 0;
    }
  }
`;

export const TeamWrapper = styled.div`
   {
    display: grid;
    z-index: 1;
    height: 100%;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 3rem;
    justify-content: center;
  }
`;

export const TeamRow = styled.div`
   {
    display: grid;
    grid-auto-columns: minmax(auto, lfr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 768px) {
      grid-template-areas: ${({ imgStart }) =>
        imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
      margin-left: 0px;
      text-align: center;
    }
  }
`;

export const Column1 = styled.div`
   {
    grid-area: col1;
    margin: 4rem 4rem 0;
    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }
`;

export const Column2 = styled.div`
   {
    margin: 2rem 4rem 0;
    grid-area: col2;
    @media screen and (max-width: 768px) {
      margin-bottom: 3rem;
    }
  }
`;

export const TextWrapper = styled.div`
   {
    padding-top: 0;
    padding-bottom: 60px;
  }
`;

export const TopLine = styled.div`
   {
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
`;

export const Heading = styled.h1`
   {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

    @media screen and (max-width: 480px) {
      font-size: 32px;
    }
  }
`;

export const MemberName = styled.h1`
   {
    margin: 1rem 0 0 0;
    font-size: 1.5rem;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

    @media screen and (max-width: 480px) {
      font-size: 1rem;
    }
  }
`;

export const Paragraph = styled.p`
   {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1.5;
    color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
    @media screen and (max-width: 768px) {
        text-align: center;
        margin-right: 0;
    }
  }
`;

export const Role = styled.p`
   {
    max-width: 440px;
    margin: 0 1rem 0 0;
    
    font-size: clamp(12px, 2vw, 14px);
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
  }
`;

export const ImgWrapper = styled.div`
   {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;    
    height: 100%;
    margin: .8rem 0;
  }
`;

export const ImgMember = styled.img`
   {
    width: 40%;
    margin: 0;
    padding-right: 0;

    @media screen and (max-width: 480px) {
      width: 70%;
    }
  }
`;

export const Img = styled.img`
   {
    width: 100%;
  }
`;
