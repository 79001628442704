import React, {useState} from 'react'
import HeroSection from '../components/heroSection'
import InfoSection from '../components/infoSection'
import Navbar from '../components/navbar'
import Sidebar from '../components/sidebar'
import {section1, section2} from '../components/infoSection/Data'
import Foooter from '../components/footer'
import Form from '../components/form'
import Team from '../components/team'
import Blog from '../components/blog'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toogle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
    <Sidebar isOpen={isOpen} toogle={toogle}/>
    <Navbar isOpen={isOpen} toogle={toogle}/>
    <HeroSection/>
    <InfoSection {...section1}/>
    <Blog/>
    <Team {...section2}/>
    <Form/>
    <Foooter/>
    </>
    
  )
}

export default Home