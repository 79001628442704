import React from "react";
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  Img,
  ImgWrapper,
} from "./NavbarElements";
import { FaBars } from "react-icons/fa";
import img from "../../assets/Voyance_Horiz.webp";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { SocialIconLink } from "./NavbarElements";

const Navbar = ({ toogle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <ImgWrapper>
            <Img src={img} />
          </ImgWrapper>
          <MobileIcon onClick={toogle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {/* <NavItem>
          <NavLinks to='home' smooth={true}>Home</NavLinks>
        </NavItem>
        <NavItem>
          <NavLinks to='product' smooth={true}>Product</NavLinks>
        </NavItem>
        <NavItem>
          <NavLinks to='about' smooth={true}>About</NavLinks>
        </NavItem> */}
            <NavItem>
              {/* <NavLinks to='contact' smooth={true}>Contact</NavLinks> */}
              {/* https://twitter.com/voyancelabs */}
              <SocialIconLink
                href="https://twitter.com/VoyanceSystems"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
            </NavItem>
            <NavItem>
              <SocialIconLink
                href="https://www.linkedin.com/company/voyance-systems"
                target="_blank"
                aria-label="Linkedin"
              >
                <FaLinkedin />
              </SocialIconLink>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
