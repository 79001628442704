import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'
import {Link} from 'react-scroll'

export const HeroContainer = styled.div`{
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
}`

export const HeroBg = styled.div`{
    position: absolute;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;

    :before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2)0%, rgba(0,0,0,0.6)100%), linear-gradient(180deg, rgba(0,0,0,0.2)0%, transparent 100%);
        z-index: 2;
    }

    @media screen and (min-width: 768px) {
    }
}`

export const VideoBg = styled.video`{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
}`

export const HeroContent = styled.div`{
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}`

export const HeroH1 = styled.h1`{
    color: #fff;
    font-size: 60px;
    text-align: center;
    font-family: 'Mukta', sans-serif;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
}`

export const HeroP = styled.p`{
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
    font-family: 'Mukta', sans-serif;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
}`

export const HeroBtnWrapper = styled.div`{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}`

export const ArrowForward = styled(MdArrowForward)`{
    margin-left: 8px;
    font.size: 20px;
}`

export const ArrowRight = styled(MdKeyboardArrowRight)`{
    margin-left: 8px;
    font.size: 20px;
}`

export const Button = styled(Link)`{
    border-radius: 10px;
    background: ${({ primary}) => (primary ? '#01bf71': '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px': '12px 30px')};
    font-size: ${({ fontBig}) => (fontBig ? '16px': '12px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 20px 20px -20px rgba(0,0,0,1);

    &:hover{
        transition: all 0.1s ease-in-out;
        background: ${({ primary}) => (primary ? '#000': '#01bf71')};
        color: ${({ primary}) => (primary ? '#fff': '#000')};
        animation: headShake;
        animation-duration: 1s;
    }
}`