import React, {useState} from 'react'
import Navbar from '../components/navbar'
import Sidebar from '../components/sidebar'
import Foooter from '../components/footer'
import ArticleSection from '../components/article'

const Article = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toogle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
    <Sidebar isOpen={isOpen} toogle={toogle}/>
    <Navbar isOpen={isOpen} toogle={toogle}/>
    <ArticleSection/>
    <Foooter/>
    </>
    
  )
}

export default Article