import React from 'react'
import {Button as Button2, HeroBtnWrapper, InfoContainer, InfoWrapper, InfoRow, Column1, Column2, 
  TextWrapper, TopLine, Heading, Paragraph, ImgWrapper, Img, Column3} from './InfoSectionElements'

const InfoSection = ({lightBg, imgStart, topline, headline, p1,p2,p3,p4,p5, img, id, lightText, darkText}) => {
  return (
    <>
    <InfoContainer lightBg={lightBg} id={id}>
      <InfoWrapper>
        <InfoRow imgStart={imgStart}>
          <Column1>
          <TextWrapper>
            <TopLine>{topline}</TopLine>
            <Heading lightText={lightText}>{headline}</Heading>
            <Paragraph darkText={darkText}>{p1}</Paragraph>
            <Paragraph darkText={darkText}>{p2}</Paragraph>
            {/* <Paragraph darkText={darkText}>{p3}</Paragraph>
            <Paragraph darkText={darkText}>{p4}</Paragraph>
            <Paragraph darkText={darkText}>{p5}</Paragraph> */}
          </TextWrapper> 
          </Column1>
          
          <Column2>
          <ImgWrapper><Img src={img}/></ImgWrapper>
          <HeroBtnWrapper>
              <Button2 primary='true' dark='false' to="blog" smooth={true}>News</Button2>
            </HeroBtnWrapper>
          </Column2>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
    </>
  )
}

export default InfoSection