import './App.css';
import Home from './pages';
import {HashRouter as Router, Route, Routes} from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import Article from './pages/article';

function App() {
  return (
    <ChakraProvider>
      <Router>
      <Routes>
        <Route path='/' exact element={<Home/>}></Route>
        <Route path='/home' exact element={<Home/>}></Route>
        <Route path='/article' exact element={<Article/>}></Route>
      </Routes>
    </Router>
    </ChakraProvider>
    
  );
}

export default App;
