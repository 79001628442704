import art1 from '../../assets/articles/article1.webp';
import art2 from '../../assets/articles/vivatech.webp';

export const article1 = {
    image: art1,
    title: 'Articles',
    head: 'Why model-based analytics?',
    description: 'Model-based analytics for comprehensive analysis.',
    date: 'Jun 07, 2022',
    alt: 'https://www.pexels.com/es-es/foto/cafe-taza-telefono-inteligente-escritorio-106344/'
  }

  export const article2 = {
    image: art2,
    title: 'Events',
    head: 'Voyance will be at VivaTech’22',
    description: 'Voyance will be at VivaTech’22 on Thursday, 16th June 2022 at stand K10-008. Come, visit us.',
    date: 'Jun 07, 2022',
    alt: "https://www.presse-citron.net/vivatech-conferences-startups-que-sest-il-passe-hier/"
  }
