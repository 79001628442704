import React, { useEffect } from "react";
import { Box, Center, Heading, Text, Stack,  useColorModeValue } from '@chakra-ui/react';
import {Img, ImgWrapper, Tweets} from './BlogElements'

const TwitterContainer = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);

  return (
    <Tweets>
    
    <Center py={6}>
    <Box
        maxW={'445px'}
        w={'80%'}
        h={'485px'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}>
            <Stack>
          <Text color={'green.500'} textTransform={'uppercase'} fontWeight={800} fontSize={'sm'} letterSpacing={1.1}>
            Tweets
          </Text>
          <Heading color={useColorModeValue('gray.700', 'white')} fontSize={'2xl'} fontFamily={'body'}>
          VoyanceSystems
          </Heading>
        </Stack>
            
        <section className="twitterContainer">
      <div className="twitter-embed">
        <a
          className="twitter-timeline"
          data-theme="light"
          data-tweet-limit="2"
          data-chrome="noheader nofooter noborders"
          href="https://twitter.com/VoyanceSystems"
        />
      </div>
    </section>
      </Box>
    </Center>
    </Tweets>
    
  );
};

export default TwitterContainer;