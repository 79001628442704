import React from 'react'
import { FooterContainer, FooterWrap, FooterLink, SocialMedia, SocialMediaWrap, WebsiteRights, SocialIcons, WebsiteCredits, A } from './FooterElements'
const Footer = () => {
  return (
    <FooterContainer>
        <FooterWrap>
            <SocialMedia>
                <SocialMediaWrap>
                    <WebsiteRights>Voyance © {new Date().getFullYear()} All rights reserved. <br/>
                    {/* <A href='https://www.inria.fr/fr/centre-inria-grenoble-rhone-alpes' target="_blank">INRIA</A><br/> */}
                    <WebsiteCredits>Video from <A href='//www.pexels.com/es-es/video/manos-gente-oficina-trabajando-6774772/' target="_blank">Kindel</A></WebsiteCredits></WebsiteRights>
                    <SocialIcons>
                        <FooterLink to="product" smooth={true}>Product</FooterLink>
                        <FooterLink to="about" smooth={true}>About</FooterLink>
                        <FooterLink to="contact" smooth={true}>Contact</FooterLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer