import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label, subject }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

export default ButtonMailto;


// const ButtonMailto =({ email, subject, body, ...props })=> {
//     return (
//       <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
//         {props.children}
//       </a>
//     );
//   }

//   export default ButtonMailto;
  