import React from 'react'
import { Box, Center, Heading, Text, Stack,  useColorModeValue } from '@chakra-ui/react';
import {Img, ImgWrapper, LinkArt} from './BlogElements'

const BlogItem = ({image, title, head, description, date, image2, title2, head2, description2, date2, image3, title3, head3, description3, date3  }) => {
  return (
    <>
    <LinkArt to={'/article'} state={{image: image, title: title, head: head, description: description, date: date,
          image2: image2, title2: title2, head2: head2, description2: description2, date2: date2,
          image3: image3, title3: title3, head3: head3, description3: description3, date3: date3   }}>
      
    <Center py={6}>
    <Box
        maxW={'445px'}
        w={'80%'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}>
        <Box h={'210px'} bg={'gray.100'} mt={-6} mx={-6} mb={6} pos={'relative'}>
        <ImgWrapper><Img src={image}/></ImgWrapper>
        </Box>
        <Stack>
          <Text color={'green.500'} textTransform={'uppercase'} fontWeight={800} fontSize={'sm'} letterSpacing={1.1}>
            {title}
          </Text>
          <Heading color={useColorModeValue('gray.700', 'white')} fontSize={'2xl'} fontFamily={'body'}>
            {head}
          </Heading>
          <Text color={'gray.500'}>
            {description}
          </Text>
        </Stack>
        <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            <Text fontWeight={600}>Achim Rolle</Text>
            <Text color={'gray.500'}>{date}</Text>
          </Stack>
        </Stack>
      </Box>
    </Center>
    </LinkArt>
    </>
  )
}

export default BlogItem