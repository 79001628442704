import React from 'react'
import {ArticleContainer, ArticleWrapper, ArticleRow, Column1, Column2, 
  TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img} from './ArticleElements'
import { useLocation } from 'react-router-dom'

const ArticleSection = () => {
  const location = useLocation()
  const data = location.state
  const img = data.image
  const img2 = data.image2
  const img3 = data.image3

  return (
    <>
    <ArticleContainer lightBg={true}>
      <ArticleWrapper>
        <ArticleRow>
          <Column1>
          <TextWrapper>
            <TopLine>{data.title}</TopLine>
            <Heading lightText={false}>{data.head}</Heading>
            <Subtitle darkText={true}>{data.description}</Subtitle>
          </TextWrapper> 
          </Column1>
          <Column2>
          <ImgWrapper><Img src={img}/></ImgWrapper>
          </Column2>
        </ArticleRow>
        <ArticleRow>
          <Column1>
          <TextWrapper>
            <TopLine>{data.title2}</TopLine>
            <Heading lightText={false}>{data.head2}</Heading>
            <Subtitle darkText={true}>{data.description2}</Subtitle>
          </TextWrapper> 
          <ImgWrapper><Img src={img2}/></ImgWrapper>
          </Column1>
        </ArticleRow>
        <ArticleRow>
          <Column1>
          <TextWrapper>
            <TopLine>{data.title3}</TopLine>
            <Heading lightText={false}>{data.head3}</Heading>
            <Subtitle darkText={true}>{data.description3}</Subtitle>
          </TextWrapper>
          <ImgWrapper><Img src={img3}/></ImgWrapper> 
          </Column1>
        </ArticleRow>
      </ArticleWrapper>
    </ArticleContainer>
    </>
  )
}

export default ArticleSection