import React from 'react'
import {BlogContainer} from './BlogElements'
import BlogItem from './BlogItem'
import { article1, article2, article3 } from './Items'
import { Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import TwitterContainer from './TwitterContainer';

const Blog = () => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)')

  return (
    <>
    <BlogContainer lightBg={false} id={'blog'}>
    {isLargerThan900 ?
    <>
    <Grid templateColumns='repeat(3, 1fr)' gap={6} className='grid'>
        <GridItem><BlogItem {...article1}></BlogItem></GridItem>
        <GridItem><BlogItem {...article2}></BlogItem></GridItem>
        <GridItem><TwitterContainer/></GridItem>
      </Grid>
    </>
    
    : <>
    <Grid templateColumns='repeat(1, 1fr)' gap={6} className='grid'>
        <GridItem><BlogItem {...article1}></BlogItem></GridItem>
        <GridItem><BlogItem {...article2}></BlogItem></GridItem>
        <GridItem><TwitterContainer/></GridItem>
      </Grid>
    </>}
    
      
    </BlogContainer>
    </>
  )
}

export default Blog