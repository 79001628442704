import React from 'react'
import { SidebarContainer, CloseIcon, Icon, SidebarWrapper, SidebarMenu,  SidebarLink } from './SidebarElements'
const Sidebar = ({toogle, isOpen}) => {
  
  return (
    <SidebarContainer isOpen={isOpen} onClick={toogle}>
      <Icon onClick={toogle}>
        <CloseIcon/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='product' onClick={toogle}>What We Do</SidebarLink>
          <SidebarLink to='about' onClick={toogle}>About</SidebarLink>
          <SidebarLink to='contact' onClick={toogle}>Contact</SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar