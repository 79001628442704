import styled from "styled-components";
import { Link } from "react-scroll";

export const InfoContainer = styled.div`
   {
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};

    @media screen and (max-width: 768px) {
      padding: 100px 0;
    }
  }
`;

export const InfoWrapper = styled.div`
   {
    display: grid;
    z-index: 1;
    height: 780px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoRow = styled.div`
   {
    display: grid;
    gap: 5rem;
    width: 100%;
    margin: auto;
    justify-content: center;
    grid-auto-columns: minmax(auto, lfr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 768px) {
      text-align: center;
      gap: 0;
      grid-template-areas: ${({ imgStart }) =>
        imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    }
  }
`;

export const Column1 = styled.div`
   {
    grid-area: col1;
    width: 100%;

    @media screen and (max-width: 480px) {
      margin-left: 0px;
    }
  }
`;

export const Column2 = styled.div`
   {
    grid-area: col2;
    width: 100%;

    @media screen and (max-width: 480px) {
      margin-left: 0px;
    }
  }
`;

export const Column3 = styled.div`
   {
    margin-left: 80px;
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col3;

    @media screen and (max-width: 480px) {
      margin-left: 0px;
    }
  }
`;

export const TextWrapper = styled.div`
   {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
  }
`;

export const TopLine = styled.div`
   {
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
`;

export const Heading = styled.h1`
   {
    margin-bottom: 24px;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

    @media screen and (max-width: 480px) {
      font-size: 32px;
    }
  }
`;

export const Paragraph = styled.p`
   {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1.5;
    color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
    @media screen and (max-width: 768px) {
        text-align: center;
        margin-right: 0;
    }
  }
`;

export const ImgWrapper = styled.div`
   {
    max-width: 450px;
    height: 100%;
    margin: auto;
    @media screen and (max-width: 768px) {
      width: 70%;
    }
  }
`;

export const Img = styled.img`
   {
    width: 100%;
    margin-right: 100px;
    padding-right: 0;

    @media screen and (max-width: 480px) {
      margin-left: 0px;
      margin-top: -70px;
      margin-bottom: 50px;
    }
  }
`;

export const Button = styled(Link)`
   {
    border-radius: 50px;
    background: ${({ primary }) => (primary ? "#01bf71" : "#010606")};
    white-space: nowrap;
    padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
    font-size: ${({ fontBig }) => (fontBig ? "16px" : "12px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 1);

    &:hover {
      transition: all 0.1s ease-in-out;
      background: ${({ primary }) => (primary ? "#000" : "#01bf71")};
      color: ${({ primary }) => (primary ? "#fff" : "#000")};
      animation: headShake;
      animation-duration: 1s;
    }
  }
`;

export const HeroBtnWrapper = styled.div`
   {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
