import React from "react";
import {
  TeamContainer,
  TeamWrapper,
  TeamRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Paragraph,
  ImgWrapper,
  Img,
  MemberName,
  ImgMember,
  Role,
} from "./TeamElements";
import ajay from "../../assets/team/ajay.webp";
import paco from "../../assets/team/paco.webp";
import gwen from "../../assets/team/gwen.webp";
import ramu from "../../assets/team/ramu.webp";
import carlos from "../../assets/team/carlos.webp";
import img from "../../assets/inria_logo.webp";
import { Grid, Box } from "@chakra-ui/react";
import Card from "./Card";

const Team = ({ headline, description, p1 }) => {
  return (
    <>
      <TeamContainer lightBg={true} id="about">
        <TeamWrapper>
          <TeamRow>
            <Column1>
              <TextWrapper>
                <TopLine>About</TopLine>
                <Heading lightText={false}>{headline}</Heading>
                <Paragraph darkText={true}>{description}</Paragraph>
                <Paragraph darkText={true}>{p1}</Paragraph>
                {/* <Paragraph darkText={true}>{p3}</Paragraph>
            <Paragraph darkText={true}>{p4}</Paragraph>
            <Paragraph darkText={true}>{p5}</Paragraph> */}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrapper>
                <a href="https://www.inria.fr/en/inria-startup-studio">
                  <Img src={img} />
                </a>
              </ImgWrapper>
            </Column2>
          </TeamRow>
          <TeamRow>
            <Grid
              templateColumns={{ md: "repeat(3, 1fr)", sm: "repeat(1, 1fr)" }}
              gap={6}
            >
              <Card
                src={paco}
                name="Francisco Durán"
                role="Scientific Lead - Analytics"
                linkedin="https://www.linkedin.com/in/francisco-dur%C3%A1n-9a617415/"
              />
              <Card
                src={ajay}
                name="Ajay Krishna"
                role="CEO"
                linkedin="https://www.linkedin.com/in/ajaykrishnamn/"
              />
              <Card
                src={gwen}
                name="Gwen Salaün"
                role="Scientific Lead - Optimization"
                linkedin="https://www.linkedin.com/in/gwen-salaun-595ba17/"
              />
            </Grid>
          </TeamRow>

          <TeamRow>
            <Grid
              templateColumns={{ md: "repeat(3, 1fr)", sm: "repeat(1, 1fr)" }}
              gap={6}
              mt={5}
            >
              <Card
                src={ramu}
                name="Ramu Munnangi"
                role="Full-stack developer"
                linkedin="https://www.linkedin.com/in/dasarada-munnangi-161782145/"
              />
              <Card
                src={carlos}
                name="Carlos Vargas"
                role="Intern"
                linkedin="https://www.linkedin.com/in/varlopecar/"
              />
            </Grid>
          </TeamRow>
        </TeamWrapper>
      </TeamContainer>
    </>
  );
};

export default Team;
