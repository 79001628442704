export const section1 = {
    id: 'product',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topline: 'Product',
    headline: 'Tailored analytics platform',
    p1: "Voyance provides integrated analytics that enable enterprises to improve efficiency and reduce GHG emissions.",
    p2: 'Voyance leverages model-based techniques to analyse different types of processes, ranging from operations workflows to complex processes in retail, manufacturing, logistics, and energy sectors. The analyses enable technical analysts and business leaders to optimize their workflows in terms of resource utilization, GHG emissions, cost, throughput, and other KPIs.',
    imgStart: false,
    img: require('../../assets/website-image.webp'),
    dark: true,
    primary: true,
    darkText: true
}

export const section2 = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topline: 'About',
    headline: 'Team',
    description: "Voyance's team has a solid experience both in  research and industry. Our main product is the result of over 25 years of combined research expertise in simulation and analysis of workflows.",
    p1: "Voyance is backed by Inria Startup Studio.",
    imgStart: false,
    img: require('../../assets/inria_logo.webp'),
    dark: true,
    primary: true,
    darkText: true
}

export const section3 = {
    id: 'contact',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topline: 'Contact',
    headline: 'headline',
    description: 'description',
    imgStart: false,
    img: require('../../assets/img2.webp'),
    dark: true,
    primary: true,
    darkText: false
}